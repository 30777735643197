@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes sharpen {
  from {
    filter: blur(8px);
  }

  to {
    filter: none;
  }
}

#edit-dialog {
  /* Hide everything but the rating and tags fields. */
  .post_has_embedded_notes_fieldset, .post_lock_fieldset, .post_parent_id, .post_source, .dropzone-container, #upload-guide-notice {
    display: none;
  }

  .upload-form {
    position: static;
  }
}

#post_tag_string {
  max-height: 10rem;
}

#has-parent-relationship-preview, #has-children-relationship-preview {
  article.post-preview.current-post {
    border-radius: 0.25rem;
    background-color: var(--preview-selected-color);
  }
}

.post-count {
  color: var(--tag-count-color);
  margin-left: 0.2em;
}

.low-post-count {
  color: var(--low-post-count-color);
}

// Put the Rating label on the same line as the radio buttons.
div.post_rating > label {
  display: inline-block !important;
  margin-right: 0.5rem;
  vertical-align: middle;
  font-size: var(--text-md);
}

div#c-posts {
  .fav-buttons {
    font-size: var(--text-lg);

    button.ui-button {
      padding: 0.25em 0.75em;
    }
  }

  form#remove-fav-button button:not([disabled]) .icon {
    color: var(--remove-favorite-button);
  }

  #remove-fav-button.animate i {
    animation: heartbeat 0.4s ease;
  }

  .fav-buttons-true #add-fav-button {
    display: none;
  }

  .fav-buttons-false #remove-fav-button {
    display: none;
  }

  #ugoira-controls {
    div#seek-slider {
      overflow: visible;
      background: var(--ugoira-seek-slider-background);

      .ui-progressbar-value {
        background: var(--ugoira-seek-slider-progressbar-background);
      }
    }

    #ugoira-load-progress {
      display: inline;
      margin-left: 17px;
    }
  }

  .post-notice {
    margin: 0.5em 0;
    padding: 0.5em;
    overflow: hidden;
    border: 1px solid var(--post-notice-border-color);

    p {
      margin-bottom: 0;
    }

    &.post-notice-parent { background: var(--post-parent-notice-background); }
    &.post-notice-child { background: var(--post-child-notice-background); }
    &.post-notice-pending { background: var(--post-pending-notice-background); }
    &.post-notice-banned { background: var(--post-banned-notice-background); }
    &.post-notice-deleted { background: var(--post-deleted-notice-background); }
    &.post-notice-resized { background: var(--post-resized-notice-background); }
    &.post-notice-search { background: var(--post-search-notice-background); }
  }

  /* override default <button> tag styling */
  #search-box-submit {
    padding: 2px 6px;
    border-left: none;
    border-radius: 0;
  }

  div#a-index {
    menu#post-sections {
      font-size: var(--text-lg);

      li {
        &.active {
          font-weight: bold;
        }
      }
    }
  }

  div#a-show {
    #post-info-source {
      word-break: break-all;
    }

    menu#post-sections {
      font-size: var(--text-lg);

      li {
        padding: 0 1em 0 0;
      }
    }

    .image-container {
      margin: 1em 0 0.5em;
      image-orientation: from-image;

      &.danbirthday::before {
        content: "";
        position: absolute;
        left: 33%;
        top: -16%;
        width: 100%;
        height: 100%;
        background: top left/20% no-repeat url("../../../../../public/images/danbirthday.png");
      }
    }

    #recommended.loading-recommended-posts {
      pointer-events: none;
      opacity: 0.5;
    }

    span.close-button {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }

    #artist-commentary {
      border: 1px solid var(--post-artist-commentary-container-border-color);
      border-radius: 3px;
      background: var(--post-artist-commentary-container-background);
      margin-bottom: 0.5em;
      padding: 0.5em;

      #original-artist-commentary, #translated-artist-commentary {
        max-height: 20em;
        overflow-y: auto;
      }
    }
  }
}

body[data-post-current-image-size="large"] #post-options .image-view-large-link,
body[data-post-current-image-size="original"] #post-options .image-view-original-link,
body[data-post-current-image-size="original"] #image-resize-notice {
  display: none;
}

/* Always show the "Resized to X% of original" notice on mobile when it exists. */
#image-resize-notice {
  @media screen and (width <= 660px) {
    display: block !important;
  }
}

body.mode-translation .note-container {
  cursor: crosshair;
}

body:not(.mode-translation) div#c-posts div#a-show #mark-as-translated-section {
  display: none;
}

div#c-post-versions, div#c-artist-versions {
  div#a-index {
    a {
      word-wrap: break-word;
    }
  }
}
