body.c-post-versions.a-index {
  #subnav-undo-selected-link {
    display: none;
  }

  .post-version-select-column {
    min-width: 2em;
  }

  ins.diff-obsolete a {
    color: var(--diff-list-obsolete-added-color);
    font-weight: bold;
  }

  del.diff-obsolete a {
    color: var(--diff-list-obsolete-removed-color);
    font-weight: bold;
  }

  td.tags-column,
  td.edits-column {
    width: 40%;
  }
}
