div#c-user-upgrades {
  div#a-new {
    summary {
      padding: 1rem 0;
    }

    table#feature-comparison {
      colgroup#basic {
        background-color: var(--user-upgrade-basic-background-color);
      }

      colgroup#gold {
        background-color: var(--user-upgrade-gold-background-color);
      }

      colgroup#platinum {
        background-color: var(--user-upgrade-platinum-background-color);
      }
    }
  }
}
