.tag-type-0 a, a.tag-type-0 {
  color: var(--general-tag-color);

  &:link, &:visited {
    color: var(--general-tag-color);
  }

  &:hover {
    color: var(--general-tag-hover-color);
  }
}

.tag-type-1 a, a.tag-type-1 {
  color: var(--artist-tag-color);

  &:link, &:visited {
    color: var(--artist-tag-color);
  }

  &:hover {
    color: var(--artist-tag-hover-color);
  }
}

.tag-type-3 a, a.tag-type-3 {
  color: var(--copyright-tag-color);

  &:link, &:visited {
    color: var(--copyright-tag-color);
  }

  &:hover {
    color: var(--copyright-tag-hover-color);
  }
}

.tag-type-4 a, a.tag-type-4 {
  color: var(--character-tag-color);

  &:link, &:visited {
    color: var(--character-tag-color);
  }

  &:hover {
    color: var(--character-tag-hover-color);
  }
}

.tag-type-5 a, a.tag-type-5 {
  color: var(--meta-tag-color);

  &:link, &:visited {
    color: var(--meta-tag-color);
  }

  &:hover {
    color: var(--meta-tag-hover-color);
  }
}
